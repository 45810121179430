import styled from 'styled-components';

const Text = styled.p`
  font-family: 'OK GUNG';
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 180%;
  color: #2e1717;
`;

export default Text;
