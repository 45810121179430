import styled from 'styled-components';

import logo from '../assets/brand_logo.png';

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 2.6rem 2rem;
`;

const Logo = styled.h1`
  display: flex;
  width: 130px;
  height: 28px;
`;

const LogoImg = styled.img`
  display: flex;
  width: 130px;
  height: 28px;
`;

const Header = ({ counselorName, leftCardNum }) => (
  <Container>
    <Logo>
      <LogoImg src={logo} alt="타로언니" />
    </Logo>
  </Container>
);

export default Header;
