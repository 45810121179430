import { useEffect } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import Text from '../components/Text';
import counselors from '../assets/counselors.png';
import { fetchAdequatePixel } from '../utils/datautils';

const Ready = ({ isReserved }) => {
  useEffect(() => {
    const pixelId = fetchAdequatePixel();
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    if (isReserved) {
      amplitude.getInstance().logEvent('reservation', { referral: 'postpay_painful_v4_2' });
      fbq('trackSingle', pixelId, 'Schedule', { value: 180, currency: 'KRW' });
    } else {
      amplitude.getInstance().logEvent('registration', { referral: 'postpay_painful_v4_2' });
    }

    gtag('event', 'conversion', { send_to: 'AW-10943576545/OUoJCMfEoOUDEOHzpuIo' });
    fbq('trackSingle', pixelId, 'CompleteRegistration', { value: 360, currency: 'KRW' });
    ttq.track('CompleteRegistration');
  }, [isReserved]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Text style={{ textAlign: 'center', marginTop: '1rem' }}>
        선생님께
        <br />
        고민이 전달되었습니다.
        <br />
        선정 결과를
        <br />
        <span style={{ color: '#E1354C' }}>문자로</span> 알려드릴게요.
        <br />
        문자를 꼭 확인해주세요.
      </Text>
      <SubText>현재 상담 대기 인원이 많아, 연락을 드리기까지 한 시간 넘게 소요될 수 있습니다.</SubText>
      <Counselors src={counselors} />
    </div>
  );
};

const SubText = styled.p`
  font-family: 'Inter';
  margin-top: 1.6rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 180%;
  text-align: center;
  color: #4b5057;
`;

const Counselors = styled.img`
  width: 30rem;
  height: 10rem;
  border-radius: 2.4rem;
  margin-top: 1.6rem;
`;

export default Ready;
